<template>
  <div class="page flex-row justify-between">
    <!-- <breadcrumbTabs /> -->
    <menuTabs />
    <div class="order-view flex-col align-center">
      <el-tabs v-model="currentTab" @tab-click="handleClick">
        <el-tab-pane label="全部订单" name="" />
        <el-tab-pane label="投放中" name="3" v-if="status3Num == 0" />
        <el-tab-pane :label="'投放中（' + status3Num + '）'" name="3" v-else />
        <el-tab-pane label="审核中" name="1" v-if="status1Num == 0" />
        <el-tab-pane :label="'审核中（' + status1Num + '）'" name="1" v-else />
        <el-tab-pane label="已结束" name="5" />
      </el-tabs>
      <div v-if="orderList.length" class="order">
        <div
          class="order-item flex-col"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div class="item-top flex-row justify-between align-center">
            <div class="flex-left flex-row align-center">
              <span @click="goDetail(item.orderId)"
                >{{ item.createTime | dateFormat }} ｜ 订单编号：{{
                  item.orderId
                }}</span
              >
              <svg-icon
                v-if="item.status != 4"
                class="share-svg"
                iconClass="share"
                @click="onShareOrder(item)"
              ></svg-icon>
              <svg-icon
                v-if="item.status == 3 || item.status == 5"
                class="pdf-svg"
                iconClass="pdf"
                @click="onDownloadPDF(item.orderId)"
              ></svg-icon>
            </div>
            <div class="payAmount">
              总计：<span>¥{{ item.payAmount }}</span>
            </div>
          </div>
          <div class="item-bottom flex-row justify-between align-center">
            <div class="order-main flex-row justify-between align-center">
              <div
                class="videoThumbnailUrl flex-row justify-center align-center"
              >
                <!-- <img
                  class="logo_seize"
                  src="@/assets/img/logo_seize.png"
                  alt=""
                /> -->
                <img
                  class="img"
                  :src="item.videoThumbnailUrl"
                  alt=""
                  @click="onClick_see(item)"
                />
                <div
                  v-if="item.status != '4'"
                  class="editVideoCover"
                  @click.stop="editVideoCover(item)">修改封面</div>
              </div>
              <div
                v-if="!item.smart"
                class="order-info flex-col justify-between"
                @click="goDetail(item.orderId)"
              >
                <span>{{ item.name | screenFilter }}</span>
                <span v-if="item.times <= 1">共投放{{ item.times }}次</span>
                <span v-else-if="item.intervalMinute == 0"
                  >共投放{{ item.times }}次，不间隔，连续投放</span
                >
                <span v-else
                  >共投放{{ item.times }}次，每{{
                    item.intervalMinute == 1440
                      ? "1天"
                      : item.intervalMinute >= 60
                      ? item.intervalMinute / 60 + "小时"
                      : item.intervalMinute + "分钟"
                  }}投放1次</span
                >
                <span>开始投放时间：{{ item.beginUnix | dateFormat }}</span>
                <span>结束投放时间：{{ item.endUnix | dateFormat }}</span>
              </div>
              <div
                v-if="item.smart"
                class="order-info flex-col justify-between"
                @click="goDetail(item.orderId)"
              >
                <span>{{ item.name | screenFilter }}</span>
                <span v-if="item.times <= 1">共投放{{ item.times }}次</span>
                <span v-else-if="item.intervalMinute == 0"
                  >共投放{{ item.times }}次，不间隔，连续投放</span
                >
                <span v-else-if="item.smartDaysTimes > 1"
                  >共投放{{ item.times }}次，每{{
                    item.intervalMinute
                  }}分钟投放1次</span
                >
                <span v-else-if="item.smartDaysTimes == 1"
                  >共投放{{ item.times }}次，每1天投放1次</span
                >
                <span v-else>-</span>
                <span>开始投放时间：{{ item.beginUnix | dateFormat }}</span>
                <span>结束投放时间：{{ item.endUnix | dateFormat }}</span>
              </div>
            </div>
            <div class="order-status flex-col align-center justify-between">
              <span v-if="item.status == 0 && item.minutes" style="color: #2bb3e3">待付款，
                {{ item.minutes }}:{{ item.seconds }}后取消</span>
              <span v-if="item.status == 0 && !item.minutes" style="color: #2bb3e3">待付款</span>
              <span
                v-else-if="item.status == 1 || item.status == 6"
                style="color: #252927"
                >审核中</span
              >
              <span v-else-if="item.status == 2" style="color: #a3a4a4"
                >已取消</span
              >
              <span v-else-if="item.status == 3" style="color: #252927"
                >投放中</span
              >
              <span v-else-if="item.status == 4" style="color: #a3a4a4"
                >审核不通过</span
              >
              <span v-else-if="item.status == 5" style="color: #a3a4a4"
                >已结束</span
              >
              <span
                class="text-btn"
                :style="
                  item.status == 3 || item.status == 5
                    ? 'margin-top: 10rem'
                    : ''
                "
                @click="goDetail(item.orderId)"
                >订单详情</span
              >
              <!-- <span
                style="margin-top: 10rem"
                class="text-btn"
                @click="onShareOrder(item)"
                >分享订单</span
              > -->
            </div>
            <div class="order-button flex-col justify-between">
              <div
                v-if="item.status == 0"
                class="pay-btn"
                id="primary-btn"
                @click="goPay(item)"
              >
                立即支付
              </div>
              <div
                v-if="item.status == 0"
                id="default-btn"
                style="margin: 10% 0 0 0"
                @click="onCancelOrder(item.orderId)"
              >
                取消订单
              </div>
              <div
                class="relaunch-btn"
                id="default-btn"
                v-if="
                  item.status == 1 ||
                  item.status == 2 ||
                  item.status == 3 ||
                  (item.status == 5 && !item.isAdminHandOver) ||
                  item.status == 6
                "
                @click="onRelaunch(item)"
              >
                再次投放
              </div>
              <div
                v-if="item.status == 2 || item.status == 4 || item.status == 5"
                id="default-btn"
                style="margin: 10% 0 0 0"
                @click="onDelOrder(item.orderId)"
              >
                删除订单
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="order-empty flex-col justify-center align-center">
        <img class="tips-icon" src="@/assets/img/empty.png" alt="" />
        <span>还没有订单记录哦</span>
      </div>
      <el-pagination
        v-if="orderList.length && total > 10"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageParams.pageLimit.page"
        :page-sizes="[10, 20, 50]"
        :page-size="pageParams.pageLimit.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <re-launch ref="refReLaunch" :launchData="launchData"></re-launch>
    <pay-type
      ref="refPayType"
      :propsData="payTypeData"
      @refreshPayStatus="getMyOrder"
      @refreshOrderStatus="handRefreshStatus"
    ></pay-type>
    <pre-video-dialog
      ref="refPreVideo"
      :videoData="preVideoData"
    ></pre-video-dialog>
    <cancel-order-dialog
      ref="refCancelOrder"
      @cancelOrder="cancelOrderOk"
    ></cancel-order-dialog>
    <del-order-dialog
      ref="refDelOrder"
      @delOrder="delOrderOk"
    ></del-order-dialog>
    <share-order-dialog
      :shareData="shareData"
      ref="refShareOrder"
    ></share-order-dialog>
    <edit-video-cover
      :videoUrl="currentOrderData.videoUrl"
      :orderId="currentOrderData.orderId"
      :videoThumbnailUrl="currentOrderData.videoThumbnailUrl"
      @editVideoCoverSuccess="editVideoCoverSuccess"
      ref="refEditVideoCover"
    ></edit-video-cover>
    <downloadPDF
      :orderId="downloadPDFOrderId"
      ref="refDownloadPDF"
    ></downloadPDF>
  </div>
</template>

<script>
/* eslint-disable-next-line import/no-cycle */
import {
  getListOrder, cancelOrder, getUserInfo, deleteOrder
} from "@/api/request";
import reLaunch from "@/components/reLaunch.vue";
import payType from "@/components/payTypeDialog.vue";
import preVideoDialog from "@/components/preVideoDialog.vue";
import cancelOrderDialog from "@/components/cancelOrderDialog.vue";
import shareOrderDialog from "@/components/shareOrderDialog.vue";
// import breadcrumbTabs from "@/components/breadcrumbTabs.vue";
import menuTabs from "@/components/menuTabs.vue";
import downloadPDF from "@/components/downloadPDF.vue";
import editVideoCover from "./components/editVideoCover.vue";
import delOrderDialog from "./components/delOrderDialog.vue";
// import bus from "../../utils/bus";

export default {
  components: {
    reLaunch,
    payType,
    preVideoDialog,
    cancelOrderDialog,
    shareOrderDialog,
    // breadcrumbTabs,
    menuTabs,
    downloadPDF,
    editVideoCover,
    delOrderDialog
  },
  data() {
    return {
      currentTab: "",
      orderList: [],
      status3Num: 0,
      status1Num: 0,
      pages: null,
      total: null,
      pageParams: {
        statusList: [],
        userId: "",
        name: "", // 屏幕名称
        skip_cal_refund: true,
        user_delete: [0],
        pageLimit: {
          limit: 10,
          page: 1,
        },
      },
      launchData: {}, // 再次投放数据
      payTypeData: {}, // 立即支付数据
      preVideoData: {}, // 预览视频数据
      shareData: {}, // 分享弹窗的数据
      currentOrderId: "",
      currentOrderData: "",
      downloadPDFOrderId: "",
      pauseSetTimeout: false
    };
  },
  created() {
    // console.log(this.userInfo.userId);
    this.pageParams.userId = this.userInfo.businessInfo.userId;
    this.getMyOrder();
    this.getStatus3Num(); // 获取投放中订单数量
    this.getStatus1Num(); // 获取审核中订单数量
  },
  mounted() {
    this.pauseSetTimeout = false;
  },
  computed: {
    canvaApi() {
      return this.$store.state.canvaApi;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    getMyOrder() {
      this.pauseSetTimeout = true;
      getListOrder(this.pageParams).then((res) => {
        // console.log(res);
        this.pages = Number(res.pageResult.pages);
        this.total = Number(res.pageResult.total);
        this.pageParams.pageLimit.limit = Number(
          res.pageResult.pageLimit.limit
        );
        this.pageParams.pageLimit.page = Number(res.pageResult.pageLimit.page);
        this.orderList = res.orderList;
        if (this.pageParams.statusList[0] == 3) {
          this.status3Num = Number(res.pageResult.total);
        }
        if (this.pageParams.statusList[0] == 1) {
          this.status1Num = Number(res.pageResult.total);
        }
        this.orderList.forEach((item) => {
          if (item.status == 0) {
            setTimeout(() => {
              this.pauseSetTimeout = false;
              this.countdown(item);
            }, 1000);
          }
        });
      });
    },
    getStatus1Num() {
      getListOrder({
        pageLimit: {
          limit: "1",
          page: "1",
        },
        userId: this.userInfo.businessInfo.userId,
        statusList: ["1", "6"],
        name: "", // 屏幕名称
        skip_cal_refund: true,
        user_delete: [0]
      }).then((res) => {
        this.status1Num = Number(res.pageResult.total);
      });
    },
    getStatus3Num() {
      getListOrder({
        pageLimit: {
          limit: "1",
          page: "1",
        },
        userId: this.userInfo.businessInfo.userId,
        statusList: ["3"],
        name: "", // 屏幕名称
        skip_cal_refund: true,
        user_delete: [0]
      }).then((res) => {
        this.status3Num = Number(res.pageResult.total);
      });
    },
    // 重新查询免费时长
    getUserInfo() {
      getUserInfo({}).then((res) => {
        this.$store.dispatch(
          "newDiscountStatusActions",
          res.userInfo.businessInfo.newDiscountStatus
        );
        this.$store.dispatch("setUserInfoActions", res.userInfo);
      });
    },
    handleClick(tab) {
      this.pageParams.statusList = [];
      this.pageParams.pageLimit.page = 1;
      if (tab.name) {
        if (tab.name == "1") {
          this.pageParams.statusList = ["1", "6"];
        } else {
          this.pageParams.statusList.push(tab.name);
        }
      }
      this.getMyOrder();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageParams.pageLimit.limit = Number(val);
      this.getMyOrder();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageParams.pageLimit.page = Number(val);
      this.getMyOrder();
    },
    onClick_see(item) {
      this.preVideoData = {
        dialogUrl: `${item.videoUrl}?${new Date().getTime()}`,
        currentScreen: item.name
      };
      setTimeout(() => {
        this.$refs.refPreVideo.openDialog();
      }, 100);
    },
    goDetail(id) {
      let BASE_URL = "";
      if (process.env.NODE_ENV == "development") {
        BASE_URL = `${window.location.protocol}//${window.location.host}`; // 开发版本使用
      } else {
        BASE_URL = process.env.VUE_APP_BASE_URL;
      }
      window.open(`${BASE_URL}/myOrder/detail?orderId=${id}`);
    },
    goPay(item) {
      this.payTypeData = {
        orderId: item.orderId,
        payType: item.payType,
        payAmount: item.payAmount,
        direct: item.direct,
        beginUnix: item.beginUnix,
      };
      setTimeout(() => {
        this.$refs.refPayType.openPayType();
      }, 100);
    },
    onCancelOrder(id) {
      this.currentOrderId = id;
      this.$refs.refCancelOrder.openCancelOrder();
    },
    onDelOrder(id) {
      this.currentOrderId = id;
      this.$refs.refDelOrder.openDelOrder();
    },
    onShareOrder(item) {
      this.shareData = {
        orderId: item.orderId,
        userName: item.userName,
        userImage: item.userImage,
      };
      setTimeout(() => {
        this.$refs.refShareOrder.openShareOrder();
      }, 100);
    },
    cancelOrderOk() {
      cancelOrder({
        order_id: this.currentOrderId,
      })
        .then(() => {
          this.getMyOrder();
          this.getUserInfo();
          this.currentOrderId = "";
        })
        .catch((code) => {
          // 订单已经支付/超时了
          if (code == "20012" || code == "20013") {
            this.getMyOrder();
            this.getUserInfo();
          }
          this.currentOrderId = "";
        });
    },
    delOrderOk() {
      deleteOrder({
        order_id: this.currentOrderId,
      })
        .then(() => {
          this.$message({
            type: "success",
            center: true,
            message: "订单已删除",
          });
          this.getMyOrder();
          this.currentOrderId = "";
        })
        .catch((code) => {
          if (code == "21005" || code == "21006") {
            this.getMyOrder();
          }
          this.currentOrderId = "";
        });
    },
    // 再次投放
    onRelaunch(item) {
      this.launchData = item;
      this.$refs.refReLaunch.openreLaunch();
    },
    // 修改视频封面
    editVideoCover(item) {
      this.currentOrderData = item;
      this.$refs.refEditVideoCover.openDialog();
    },
    editVideoCoverSuccess() {
      this.getMyOrder();
    },
    handRefreshStatus(orderId) {
      const findI = this.orderList.findIndex((v) => v.orderId == orderId);
      if (findI != -1) {
        this.orderList[findI].status = 2;
      }
    },
    onDownloadPDF(orderId) {
      this.downloadPDFOrderId = orderId;
      setTimeout(() => {
        this.$refs.refDownloadPDF.init();
      }, 100);
    },
    countdown(item) {
      if (this.currentTab > 0 || this.pauseSetTimeout || item.status != 0) return;
      // currentTime当前时间戳，futureTime接口拿到的截止时间戳
      const currentTime = parseInt(new Date().getTime() / 1000);
      const cancelTime = Number(item.cancelTime) + 1;
      if (currentTime > cancelTime) {
        this.getMyOrder();
        return;
      }
      const differSecond = cancelTime - currentTime; // 时间差
      if (differSecond < 0) {
        // 倒计时结束
        this.getMyOrder();
      } else {
        const minute =
        Math.floor(differSecond / 60) < 10
          ? `0${Math.floor(differSecond / 60)}`
          : Math.floor(differSecond / 60);
        const second =
        differSecond % 60 < 10
          ? `0${differSecond % 60}`
          : differSecond % 60;
        item.minutes = minute;
        item.seconds = second;
        this.$forceUpdate();
        setTimeout(() => this.countdown(item), 1000);
      }
    }
  },
  beforeDestroy() {
    this.pauseSetTimeout = true;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header {
  margin: 0;
  height: 100%;
  border-radius: 6rem;
}

::v-deep .el-tabs__active-bar {
  background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
  height: 2rem;
}

::v-deep .el-tabs__nav {
  height: 62rem;
}

::v-deep .el-tabs__item {
  height: 60rem;
  line-height: 60rem;
  position: relative;
  top: 2rem;
  font-size: 14rem;
}

::v-deep .el-breadcrumb {
  font-size: 14rem;
  line-height: 22rem;
}

.page {
  width: 100%;
  display: flex;
  background-color: #fff;
  min-height: calc(100vh - 233rem);
  padding: 60rem 320rem 0;
  font-size: 14rem;

  .left-view {
    .vipType {
      width: 204rem;
      height: 136rem;
      background: #f8f8f8;
      border-radius: 6rem;
      margin: 22rem 16rem 12rem 0;

      .phone {
        margin: 26rem 0 0 36rem;

        img {
          width: 28rem;
        }

        span {
          font-size: 18rem;
          color: #252927;
          line-height: 25rem;
          margin-left: 8rem;
        }
      }

      .span1 {
        font-size: 15rem;
        color: #878988;
        line-height: 21rem;
        margin: 14rem 0 4rem 43rem;
      }

      .span2 {
        font-size: 15rem;
        color: #878988;
        line-height: 25rem;
        text-align: center;
      }
    }

    .tabs {
      height: 66rem;
      margin-top: 28rem;
      border-left: 1rem solid #d8d8d8;
      position: relative;

      .tab-item {
        font-size: 18rem;
        color: #252927;
        line-height: 28rem;
        padding-left: 14rem;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          color: #8a8a8a;
        }

        &:nth-child(1) {
          position: relative;
          bottom: 6rem;
        }

        &:nth-child(2) {
          position: relative;
          top: 6rem;
        }
      }

      .slider {
        position: absolute;
        left: -3rem;
        width: 3rem;
        height: 17rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
        transition: all 0.3s ease;
      }
    }

    .left-menu {
      width: 200rem;
      margin-top: 22rem;

      .line {
        width: 3rem;
        height: 16rem;
        background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      }

      .line-span {
        line-height: 26rem;
        font-size: 18rem;
        color: #252927;
        margin-left: 14rem;
      }
    }
  }

  .my-account {
    width: 100%;
    // height: calc(100vh - 233rem);
    background-color: #fff;
    padding: 20rem 50rem;
  }

  .el-menu {
    width: 150rem;
    height: calc(100vh - 233rem);
  }

  .order-view {
    // width: calc(100% - 200rem);
    width: 1060rem;
    // height: calc(100vh - 168rem);
    margin: 44rem 0 80rem;

    .el-tabs {
      width: 100%;
      height: 62rem;
      background-color: #f8f8f8;
      margin-bottom: 15rem;
      padding: 0 0 0 20rem;
      border-radius: 6rem;
      // position: -webkit-sticky; /* Safari */
      // position: sticky;
      // top: 70rem;
    }

    .order-empty {
      width: 100%;
      height: calc(100vh - 370rem);

      img {
        height: 88rem;
      }

      span {
        font-size: 16rem;
        color: #b2b3b3;
        margin-top: 35rem;
      }
    }

    .order {
      width: 100%;

      // height: calc(100vh - 280rem);
      // overflow: scroll;
      .order-item {
        width: 100%;
        height: 200rem;
        margin-bottom: 16rem;
        background-color: #f8f8f8;
        border-radius: 6rem;

        .item-top {
          height: 57rem;
          border-bottom: 1px solid #e5e5e5;
          padding: 20rem 28rem 16rem 28rem;
          .flex-left {
            cursor: pointer;
            span {
              color: #252927;
            }
            .share-svg {
              color: #848484;
              width: 18rem;
              height: 18rem;
              margin-left: 8rem;
              &:hover {
                color: #00b6e8;
              }
            }
            .pdf-svg {
              color: #848484;
              width: 40rem;
              height: 40rem;
              margin-left: 8rem;
              &:hover {
                color: #00b6e8;
              }
            }
          }
          .payAmount {
            color: #252927;
            span {
              color: #2bb3e3;
            }
          }
        }

        .item-bottom {
          height: 70%;
          padding: 0 20rem;

          .order-main {
            height: 100%;

            .videoThumbnailUrl {
              width: 166rem;
              height: 102rem;
              margin-right: 32rem;
              border-radius: 8rem;
              overflow: hidden;
              background: #333333;
              cursor: pointer;
              position: relative;
              &:hover {
                .editVideoCover {
                  display: block;
                }
              }

              .logo_seize {
                position: absolute;
                top: 30rem;
                left: 70rem;
                width: 42rem;
                z-index: 1;
              }

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 6rem;
                z-index: 2;
              }
              .editVideoCover {
                display: none;
                width: 100%;
                height: 30rem;
                text-align: center;
                line-height: 30rem;
                border-radius: 0 0 8rem 8rem;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 3;
                font-size: 14rem;
                color: #ffffff;
                background: rgba(0,0,0,0.2);
              }
            }

            .order-info {
              cursor: pointer;

              span {
                line-height: 20rem;
                color: #a3a4a4;
              }

              span:nth-child(1) {
                color: #252927;
              }

              span:nth-child(2) {
                color: #252927;
                margin-top: 6rem;
              }

              span:nth-child(3) {
                margin: 6rem 0 4rem 0;
              }
            }
          }

          .order-status {
            .text-btn {
              cursor: pointer;
              color: #252927;
              margin-top: 14rem;
            }
          }

          .order-button {
            width: 108rem;

            div {
              width: 108rem;
              height: 36rem;
              text-align: center;
              line-height: 36rem;
              border-radius: 27rem;
              border: 1rem solid #c4c4c4;
              cursor: pointer;
              font-size: 14rem !important;
            }

            .pay-btn {
              border: none;
              color: #fff;
            }
            .relaunch-btn {
              border: 1rem solid #2bb3e3;
              color: #2bb3e3;
            }
          }
        }
      }
    }

    .el-pagination {
      // margin-bottom: 10rem;
      margin-top: 30rem;
    }
  }
}

@media (max-width: 1024px) {
  .line {
    width: 5rem !important;
    height: 20rem !important;
  }

  .line-span {
    font-size: 28rem !important;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .line {
    height: 19rem !important;
  }

  .line-span {
    font-size: 24rem !important;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .line {
    height: 18rem !important;
  }

  .line-span {
    font-size: 23rem !important;
  }
}

@media (min-width: 1367px) and (max-width: 1440px) {
  .line-span {
    font-size: 22rem !important;
  }
}

@media (min-width: 1441px) and (max-width: 1919px) {
  .line-span {
    font-size: 20rem !important;
  }
}

@media (min-width: 1920px) {
  .line-span {
    font-size: 18rem !important;
  }
}
</style>
